import { Container } from "../style";

const Footer = () => <Container>
         
    <p>
      A generative artwork 
      by&nbsp;<a href='https://www.katiacutropia.com' target='_blank' children='Katia Cutropia' rel="noreferrer"/>   
      &nbsp;and&nbsp;<a href='https://www.soundcloud.com/e_xz' target='_blank' children='Exz' rel="noreferrer"/>.
    </p>
</Container>

export default Footer;