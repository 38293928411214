import Hero from './screens/Hero';
import Reals from './screens/Reals';
import Fakes from './screens/Fakes';
import Footer from './screens/Footer';

const App = () => <>
  <Hero />
  <Reals/>
  <Fakes/>
  <Footer/>
</>;

export default App;
