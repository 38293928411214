import styled from '@emotion/styled';
import { Container } from '../style';
import IMG604 from '../assets/604.jpg'
import IMG608 from '../assets/608.jpg'

const Fakes = () => {

    return <Style>
        <h2 children='`fakes`' />
        <div>
            <p>
                The ‘reals’ are then used to train a Generative Adversarial Network (GAN), 
                a technique that allows the AI to generate a infinite number of unique nonexistent 
                places that can be represented either as a still image or a video. 
            </p>
        </div>

        <div>        
            <img src={IMG604} alt='fake604' />
            <img src={IMG608} alt='fake608' />
        </div>
    </Style>
}

export default Fakes

const Style = styled(Container)`

div {
display: grid;
grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

gap: 1em;
width: 100%;
}
img {
  max-width: 100%;
  max-height: calc(100vh - 2em);
  margin: 0 auto;
}
`