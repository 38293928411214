import styled from '@emotion/styled'
import PortraitVersion from '../assets/1x3.mp4'
import { Container } from '../style'

const Hero = () => <Style>
  <div style={{justifySelf: 'flex-end'}}>
    <h2 children='infinite state of transition'/>
    <p>
    Infinite state of transition combines photography, 
    music and artificial intelligence to create a multitude of 
    ephemeral scenes throughout the latent space.

    As the name suggests this is an ongoing process where a series of 
    unique artworks will be generated using GANs that were trained with 
    different selections of analog photographs taken by Katia Cutropia and 
    the resulting latent space is then interpolated using music composed by Exz.
    </p> 
  </div>
  <div>
    <video 
        src={PortraitVersion} 
        autoPlay 
        muted 
        loop 
        controls
    />
  </div>
</Style>

const Style = styled(Container)`
display: grid;
grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
gap: 1em;
min-height: calc(100vh - 2em);

video {
  max-height: calc(100vh - 2em);
  margin: 0 auto;
}
div {
  justify-self: center;
  margin-bottom: 1em;
}
`

export default Hero