import styled from '@emotion/styled';
import { Container } from '../style';
import REALSIMG from '../assets/reals.jpg'

const Reals = () => {
    return <Style>
        <div>
            <h2 children='`reals`' />
            <p>
                The process starts by feeding the machine learning
                algorithm with a selection of analog photographs taken
                by Katia Cutropia around the globe between 2010 and
                2022. <br/>
                Katia is a Berlin based visual artist that explores the
                contrast between the ephemeral scenes and solid
                structures. 
                See all original shots at <a href='https://www.katiacutropia.com' target='_blank' children='www.katiacutropia.com' rel="noreferrer"/>  
            </p> 
        </div>         
        <img src={REALSIMG} alt='reals'/>

    </Style>
}

export default Reals

const Style = styled(Container)`

display: grid;
grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
gap: 1em;

img {
  grid-column: span 2;
  max-width: 100%;
  max-height: calc(100vh - 2em);
  margin: 0 auto;
}
`